import { default as indexhRwBHmYajNMeta } from "/var/www/nuxt-v3/prod/releases/11/pages/index.vue?macro=true";
import { default as simulation_45pinelysrbbqEhoSMeta } from "/var/www/nuxt-v3/prod/releases/11/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptz3JWX25F8tUMeta } from "/var/www/nuxt-v3/prod/releases/11/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexhRwBHmYajNMeta?.name ?? "index",
    path: indexhRwBHmYajNMeta?.path ?? "/",
    meta: indexhRwBHmYajNMeta || {},
    alias: indexhRwBHmYajNMeta?.alias || [],
    redirect: indexhRwBHmYajNMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/11/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pinelysrbbqEhoSMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pinelysrbbqEhoSMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pinelysrbbqEhoSMeta || {},
    alias: simulation_45pinelysrbbqEhoSMeta?.alias || [],
    redirect: simulation_45pinelysrbbqEhoSMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/11/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptz3JWX25F8tUMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptz3JWX25F8tUMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptz3JWX25F8tUMeta || {},
    alias: simulation_45ptz3JWX25F8tUMeta?.alias || [],
    redirect: simulation_45ptz3JWX25F8tUMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/11/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]